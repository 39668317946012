import { prepareDatetimes } from '~/api/helpers'

export function searchProjects (data) {
  return apiFetch('/projects', { params: data }).then(prepareDatetimes)
}

export function getProjectsMeta () {
  return apiFetch(`/projects/meta`)
}

export function getAllMyProjects (data) {
  return searchProjects({ only_my: 1, sort: 'new_first', per_page: 10000, ...data })
}

export function getProject (id) {
  return apiFetch(`/projects/${id}`).then(prepareDatetimes)
}

export function createProject (data) {
  return apiFetch('/projects', { method: 'POST', body: data })
}

export function editProject (id, data) {
  return apiFetch(`/projects/${id}`, { method: 'POST', body: { _method: 'PATCH', ...data } })
}

export function viewProject (id) {
  return apiFetch(`/projects/${id}/views`, { method: 'POST' })
}

export function closeProject (id) {
  return apiFetch(`/projects/${id}/close`, { method: 'PATCH' })
}

export function openProject (id) {
  return apiFetch(`/projects/${id}/open`, { method: 'PATCH' })
}

export function deleteProject (id) {
  return apiFetch(`/projects/${id}`, { method: 'DELETE' })
}

export function sendResponseToVacancy (id) {
  return apiFetch(`/projects/vacancies/${id}/responses`, { method: 'POST' })
}

export function getProjectResponses (id, data = {}) {
  return apiFetch(`/projects/${id}/responses`, { params: data })
}

export function acceptProjectResponse (id) {
  return apiFetch(`/projects/responses/${id}/accept`, { method: 'PATCH' })
}

export function readAllProjectResponses (id) {
  return apiFetch(`/projects/${id}/responses/read-all`, { method: 'PATCH' })
}


export function searchSpecialists (data) {
  return apiFetch('/specialists', { params: data }).then(prepareDatetimes)
}

export function getSpecialistsMeta () {
  return apiFetch(`/specialists/meta`)
}

export function sendRequestToSpecialistForVacancy (specialistId, vacancyId) {
  return apiFetch(`/projects/vacancies/${vacancyId}/requests/${specialistId}`, { method: 'POST' })
}

export function acceptProjectRequest (id) {
  return apiFetch(`/projects/vacancies/requests/${id}/accept`, { method: 'PATCH' })
}

export function declineProjectRequest (id) {
  return apiFetch(`/projects/vacancies/requests/${id}/decline`, { method: 'PATCH' })
}

export function openProjectRequest (id) {
  return apiFetch(`/projects/requests/${id}/open`, { method: 'PATCH' })
}

export function openSpecialistContacts (id) {
  return apiFetch(`/projects/specialist/${id}/open`, { method: 'PATCH' })
}
